import React from 'react';
import { useCountUp } from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const Counter = ({ end, decimals = 0 }) => {
  const { start, countUp } = useCountUp({
    end,
    decimals,
    prefix: '$',
    suffix: ' k',
    duration: 3,
  });

  return (
    <VisibilitySensor
      onChange={(isVisible) => {
        if (isVisible) start();
      }}
    >
      <h2 className="font-weight-bold">{countUp}</h2>
    </VisibilitySensor>
  );
};

const Compare = () => (
  <section className="sec_pad">
    <div className="triangle_shape" />
    <div className="container">
      <div>
        <div className="row">
          <div className="col-md-4 col-sm-12 text-center">
            <h5>Hiring a Full Time Employee</h5>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <h4>VS.</h4>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <h5>Using OPNA</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-12 text-center">
            <Counter end={150} />
            <p>Annual Salary</p>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <h4>VS.</h4>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <Counter end={72} />
            <p>Project Pay</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-12 text-center">
            <Counter end={75} />
            <p>Benefits</p>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <h4>VS.</h4>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <h2>$0 k</h2>
            <p>Benefits</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-12 text-center">
            <Counter end={9} />
            <p>Perks (gym, lunch, bonus, etc)</p>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <h4>VS.</h4>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <h2>$0 k</h2>
            <p>Perks (gym, lunch, bonus, etc)</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-12 text-center">
            <Counter end={1.6} decimals={1} />
            <p>General Admin Overhead</p>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <h4>VS.</h4>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <h2>$0 k</h2>
            <p>General Admin Overhead</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-12 text-center">
            <h5>Starts in 2 months, needs training</h5>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <h4>VS.</h4>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <h5>Starts next week, ready to hit the ground</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-12 text-center">
            <Counter end={205} />
            <h5>Total Cost to You!</h5>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <h4>VS.</h4>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <Counter end={72} />
            <h5>Total Cost to You!</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Compare;
