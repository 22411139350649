import React from 'react';
import Reveal from 'react-reveal';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Compare from '../../components/Service/StaffAug/Compare';
import urls from '../../data/urls';

const StaffAugmentation = () => {
  const imgData = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "staff-aug"}) {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
    }
  `);
  const image = imgData.contentfulAsset.fluid;

  return (
    <div>
      <section className="sec_pad">
        <Reveal bottom cascade>
          <div className="container row mx-auto">
            <div className="col-lg-7">
              <h2 className="f_p f_size_30 f_700 t_color3 l_height45 mb-30">Faster, Better, Cheaper</h2>
              <p className="f_400">
                Competition for top engineers is fierce. Recruiting senior candidates to your team is expensive and can take anywhere from 1-6 months. Your MVP can’t wait that long! Work with OPNA to gain cost effective access to peace of mind.
                <br />
                Our senior engineers and architects are available at hourly rates that aren’t inflated by the overhead of benefits, administration and supplies. You can pull us in for short term engagements and specific projects, or have us on hand throughout the year on retainer.
              </p>
              <Link to={urls.contactUs} className="app_btn btn_hover my-4">Get A Quote!</Link>
            </div>
            <div className="col-lg-5 d-flex align-items-center" >
              <Img fluid={image} className="w-75 mx-auto"/>
            </div>
          </div>
        </Reveal>
      </section>
      <Compare />
      <section className="sec_pad">
        <Reveal bottom cascade>
          <div className="container">
            <div className="app_featured_content">
              <h2 className="f_p f_size_30 f_700 t_color3 l_height45 mb-30">We can provide the following experts on a full or part-time basis.</h2>
              <div className="row">
                <ul className="col">
                  <li>Java Engineers</li>
                  <li>JavaScript Engineers</li>
                  <li>Full Stack Developers</li>
                  <li>Python Developers</li>
                  <li>DevOps Engineers</li>
                  <li>DevOps Architects</li>
                  <li>Scrum Master</li>
                </ul>
                <ul className="col">
                  <li>Project Managers</li>
                  <li>Lead Engineers</li>
                  <li>UX Developers</li>
                  <li>Mobile Developers</li>
                  <li>React Engineers</li>
                  <li>ReactNative Engineers</li>
                  <li>QA Engineers</li>
                </ul>
              </div>
              <Link to={urls.contactUs} className="app_btn btn_hover mt_40">Inquire about Staff Augmentation</Link>
            </div>
          </div>
        </Reveal>
      </section>
    </div>
  );
}
export default StaffAugmentation;
